import React, { ReactNode, useEffect, useState } from 'react';
import {
  Button,
  Paper,
  Autocomplete,
  TextField,
  Chip,
  Stack,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  OutlinedInput,
  Popover,
  Tooltip,
  IconButton,
} from '@mui/material';
import {
  useAppDispatch,
  useAppSelector,
} from '../../../../../../redux/store.model';
import { useShowErrorMessage } from '../../../../../../handlingErrors';
import ValidationUtil from '../../../../../../util/ValidationUtil';
import fetchRoles from '../../../../../../redux/roles/actions';
import { getPolyglot } from '../../../../../../i18n';
import getRolesNamesDescriptions from '../Constants';
import { STModal } from '../../../../../commons/Modal';
import {
  IUser,
  UserAPIResponse,
} from '../../../../../../redux/users/api/user.model';
import { useShowMessage } from '../../../../../../util/hooks';
import { MENU_PROPS } from './constants';
import { getRightUserUtilSelector } from '../../../../../../redux/rights/selectors';
import { getAllUsers } from '../../../../../../redux/users/selectors';
import { openNewUsersConfirmationDialog } from '../NewUsersConfirmationDialog/NewUsersConfirmationDialog';
import AuthCalls from '../../../../../../redux/auth/api/AuthCalls';
import { USER_REGISTER_STATUS } from '../../../../../../redux/auth/api/auth.model';
import {
  putGroupUsers,
  putGroupUsersByEmail,
  putGroupUsersWithRoles,
} from '../../../../../../redux/groups/actions/thunks';
import { postUsersRightsByEmail } from '../../../../../../redux/users/actions';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

export interface GroupUserAssignOwnProps {
  groupId: string;
  usersToAssign?: (IUser | UserAPIResponse)[];
  allowAssignNewUsers?: boolean;
  closeGroupUserAssign: (event: boolean) => Promise<void>;
  labelInfo?: ReactNode;
}

export default function GroupUserAssign({
  groupId,
  usersToAssign = [],
  allowAssignNewUsers = true,
  closeGroupUserAssign,
  labelInfo,
}: GroupUserAssignOwnProps) {
  const polyglot = getPolyglot();
  const validationUtil = new ValidationUtil(polyglot);
  const [usersOptions, setUserOptions] = useState<Record<string, any>[]>([]);
  const [usersEmails, setUsersEmails] = useState<string[]>([]);
  const [inputValue, setInputValue] = useState<string>('');
  const [newRolesIdList, setNewRolesIdList] = useState<string[]>([]);

  const dispatch = useAppDispatch();
  const roles = useAppSelector((state) => state.roles.items);
  const rightsUserUtil = useAppSelector(getRightUserUtilSelector);
  const users = useAppSelector((state) => getAllUsers(state));
  const showRoles = rightsUserUtil.hasRightsToReadRoles(groupId);
  const showError = useShowErrorMessage();
  const showSuccess = useShowMessage();

  const handleSetUserEmails = (e: any, data: string[]) => {
    const validData = data
      .map((item) => item.trim())
      .filter((item) => item.length > 0);
    const validValues = validData.filter((item) => {
      const res = validationUtil.validateEmailAddress(item, false);
      if (!res) {
        showError(`${item} ${polyglot.t('error.formular.enter_valid_email')}`);
      }
      return res;
    });
    setUsersEmails(validValues);
    setInputValue('');
  };

  const handleCloseGroupUserAssign = async (updateGroup: boolean) => {
    await closeGroupUserAssign(updateGroup);
  };

  const setRolesId = (event: unknown, data: any) => {
    const value = data.props.value;
    if (newRolesIdList.includes(value)) {
      setNewRolesIdList((prevStrings) =>
        prevStrings.filter((string) => string !== value)
      );
    } else {
      setNewRolesIdList((prevStrings) => prevStrings.concat(value));
    }
  };
  const getNewUsers = (emails: string[]): Promise<string[]> => {
    const calls = new AuthCalls();
    return calls
      .checkUsers(emails)
      .then((usersChecked: any) =>
        Object.keys(usersChecked).filter(
          (email) => usersChecked[email] === USER_REGISTER_STATUS.UNKNOWN
        )
      );
  };
  const assignGroupUser = async () => {
    try {
      if (usersEmails.length === 0) {
        throw new Error(polyglot.t('error.formular.empty_list'));
      } else {
        /* filter users via email */
        const usersByEmail = usersEmails.filter(
          (email) => !users.find((user) => user.email === email)
        );
        /* filter users via id */
        const usersFoundIds = users
          .filter((user) => usersEmails.includes(user.email))
          .map((user) => user.id);

        /* Invite users via email */
        if (usersByEmail.length > 0) {
          const newUsers = await getNewUsers(usersByEmail);
          if (newUsers.length > 0) {
            /** Invite those users first, stopping this flow */
            const resultonfirmation = await openNewUsersConfirmationDialog({
              emails: newUsers,
            }).catch((value) => value);
            if (!resultonfirmation) return;
          }
        }

        /* Assign users with id */
        if (usersFoundIds.length > 0) {
          if (newRolesIdList && newRolesIdList.length > 0) {
            await dispatch(
              putGroupUsersWithRoles(groupId, usersFoundIds, newRolesIdList)
            );
          } else {
            await dispatch(putGroupUsers(groupId, usersFoundIds));
          }
        }

        /* Assign users via email */
        if (usersByEmail.length > 0) {
          await dispatch(putGroupUsersByEmail(groupId, usersByEmail));

          if (newRolesIdList && newRolesIdList.length > 0) {
            await dispatch(
              postUsersRightsByEmail(usersByEmail, newRolesIdList, groupId)
            );
          }
        }
        handleCloseGroupUserAssign(true);
        const message =
          usersEmails.length > 1
            ? polyglot.t('group.users.assign_several_successful_message')
            : polyglot.t('group.users.assign_successful_message');
        showSuccess(message);
      }
    } catch (error) {
      showError(error);
    }
  };

  const loadData = async () => {
    try {
      const usersOptions = usersToAssign.map((user) => {
        const name = user.name ? user.name : user.email;
        return {
          key: user.id,
          value: user.email,
          text: !name ? user.id : name,
        };
      });
      setUserOptions(usersOptions);
      if (showRoles) {
        await dispatch(fetchRoles());
      }
    } catch (error: any) {
      showError(error);
    }
  };

  useEffect(() => {
    loadData();
  }, []);

  const rolesOptions = getRolesNamesDescriptions(roles).map((r) => ({
    key: r.id,
    value: r.id,
    text: r.name,
    icon: r.description,
  }));

  const label = labelInfo ?? (
    <Popover
      open={true}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      {`${polyglot.t(
        'group.users.select_user_or_insert_email_address'
      )} ${polyglot.t('group.users.invite_user_via_email_needs_role_id')}`}
    </Popover>
  );

  return (
    <div>
      <STModal
        open
        onClose={() => handleCloseGroupUserAssign(false)}
        title={polyglot.t('group.users.assign_dialog_title')}
        scroll="body"
        sx={{
          '&& .MuiDialogContent-root,.MuiPaper-root': {
            overflowY: 'initial',
            minWidth: '600px',
          },
        }}
        buttonActions={
          <>
            <Button
              onClick={() => handleCloseGroupUserAssign(true)}
              variant="text"
            >
              {polyglot.t('group.cancel_button_title')}
            </Button>
            <Button variant="contained" onClick={assignGroupUser}>
              {polyglot.t('group.users.assign_user_button_title')}
            </Button>
          </>
        }
      >
        <form className="formular-material-design">
          <Stack spacing={2}>
            <Autocomplete
              multiple
              freeSolo={allowAssignNewUsers}
              options={usersOptions.map((option) => option.value)}
              value={usersEmails}
              onChange={(event: any, newValue) =>
                handleSetUserEmails(event, newValue)
              }
              inputValue={inputValue}
              onInputChange={(event, newInputValue) =>
                setInputValue(newInputValue)
              }
              autoSelect
              onKeyDown={(e: any) => {
                if (e.code === 'Space') {
                  handleSetUserEmails(e, [
                    ...usersEmails,
                    e.target.value,
                  ] as string[]);
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label={polyglot.t('group.users.user')}
                  placeholder={polyglot.t('group.users.user')}
                />
              )}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    variant="outlined"
                    label={option}
                    {...getTagProps({ index })}
                    key={index}
                  />
                ))
              }
              noOptionsText={polyglot.t('group.users.no_users_to_assign')}
              id="users"
            />
            {label}
            {rolesOptions.length > 0 && (
              <FormControl fullWidth>
                <InputLabel id="roles-input">
                  {polyglot.t('group.users.role')}
                </InputLabel>
                <Select
                  labelId="roles-input"
                  id="roles"
                  multiple
                  value={newRolesIdList}
                  onChange={(event, data) => setRolesId(event, data)}
                  MenuProps={MENU_PROPS}
                  renderValue={(selected) =>
                    selected
                      .map((role) => {
                        return rolesOptions.find((item) => item.value === role)
                          ?.text;
                      })
                      .join(', ')
                  }
                >
                  {rolesOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.text} {option.icon}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          </Stack>
        </form>
      </STModal>
    </div>
  );
}
